<template>
       <div id="bg">
        <!-- <navbar /> -->
        <router-view />
  
        <!-- <site-footer /> -->
       </div>
  
  </template>
  
  <script>
  import SiteFooter from './partials/SiteFooter.vue'
  import Navbar from './partials/Navbar.vue'
  import '@/website-assets/css/plugins.min.css'
  import '@/website-assets/plugins/fontawesome/css/font-awesome.min.css'
  import '@/website-assets/plugins/line-awesome/css/line-awesome.min.css'
  import '@/website-assets/plugins/flaticon/flaticon.css'
  import '@/website-assets/plugins/themify/themify-icons.css'
  import '@/website-assets/css/style.min.css'
  import '@/website-assets/css/templete.min.css'
  import '@/website-assets/css/skin/skin-1.css'
 

  
  export default {
    components: {
      Navbar,
      SiteFooter,
  
    },
  }
  </script>

  